import React, { useEffect } from 'react';
import * as THREE from  'three';

import { fragmentShader, vertexShader } from './shaders/blurredColors';

interface ShaderBackgroundProps {
    container: React.RefObject<HTMLDivElement>;
    darkMode: boolean;
}

const ShaderBackground = (props: ShaderBackgroundProps) => {
    const random = new THREE.Vector2(Math.random(), Math.random());

    useEffect(() => {
        if(props.container.current === null) {
            console.error("ShaderBackground container is null");
            return;
        }

        // Set up the scene
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, props.container.current.clientWidth / props.container.current.clientHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();

        renderer.setSize(props.container.current.clientWidth, props.container.current.clientHeight);
        props.container.current.appendChild(renderer.domElement);

        const geometry = new THREE.PlaneGeometry(2, 2);

        let foreground: number, background: number;
        if(props.darkMode){
            foreground = 0.2;
            background = 0.0;
        }
        else{
            foreground = 0.8;
            background = 0.9;
        }

        // Use shader code (imported from a separate file)
        const material = new THREE.ShaderMaterial({
            fragmentShader,
            vertexShader,
            uniforms: {
                iTime: { value: 0 },
                iResolution: { value: new THREE.Vector3() },
                foregroundCol: { value: foreground },
                backgroundCol: { value: background },
                random: { value: random },
                darkMode: { value: props.darkMode },
            },
        });

        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);


        // Create an animation loop
        const animate = () => {
            material.uniforms.iTime.value += 0.005;
            const canvas = renderer.domElement;
            material.uniforms.iResolution.value.set(canvas.width, canvas.height, 1);

            requestAnimationFrame(animate);

            // Render the scene
            renderer.render(scene, camera);
        };
        animate();

        // Handle window resize
        const handleResize = () => {
            if (props.container.current === null) {
                console.error("ShaderBackground container is null");
                return;
            }
            const newWidth = props.container.current.clientWidth;
            const newHeight = props.container.current.clientHeight;

            camera.aspect = newWidth / newHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(newWidth, newHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup code
        return () => {
            window.removeEventListener('resize', handleResize);
            if(props.container.current !== null) {
                props.container.current.removeChild(renderer.domElement);
            }
        };

    }, [props]);

    return null; // Component isn't rendered, the code appends the canvas directly
};

export default ShaderBackground;
