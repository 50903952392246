import {Divider} from "@mui/material-next";
import {useEffect, useState} from "react";
import PhotoCollage from "../components/PhotoCollage";
import Sorted from "../components/doodles/Sorted";
import {useDarkMode} from "../contexts/DarkModeContext";
import styles from "./About.module.css";

const images = [
    "/about-images/1.jpg",
    "/about-images/3.jpg",
    "/about-images/2.jpg",
]

function About() {

    const [mobile, setMobile] = useState(window.matchMedia('(min-width: 767px)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        const handler = (e: any) => setMobile(e.matches);
        mediaQuery.addEventListener("change", handler);
        setMobile(mediaQuery.matches);

        return () => {
            mediaQuery.removeEventListener("change", handler);
        };
    }, []);


    useEffect(() => {
        document.title = "About";
    }, []);
    const { isDarkMode } = useDarkMode();

    return (
        <>
            <Divider variant="middle" />
            <div className={styles.container}>
                <div className={styles.content}>
                    {mobile && <img src={images[2]} className={styles.mobileImage} alt={"Image"}/>}
                    <div className={styles.title}>
                        <Sorted darkMode={isDarkMode} tagline={""} title={"Hi, I'm Bryan"} />
                    </div>
                    <h3>
                        I'm a 3rd year Computer Science student at the Technical University of Delft.
                        I'm mostly interested in low-level programming, embedded systems and the like.
                        However, anything remotely related to computers will probably catch my interest.
                    </h3>
                    <h3>
                        This website is a collection of various projects I've done and pieces of knowledge I've collected over the years.
                        I'm also using this domain to host various other projects.
                        You can view the code for most of my projects on my <a
                            href={"https://www.github.com/bbvandermark"}
                            style={{color: isDarkMode ? "white" : "black"}}
                        >GitHub</a> page.
                    </h3>
                    <h3>
                        If you'd like to get in touch, you can reach me at <a
                            href={"mailto:bryan@bruhyan.com"}
                            style={{color: isDarkMode ? "white" : "black"}}
                        >bryan@bruhyan.com</a>.
                        <br />
                        For professional inquiries, please send an email to <a
                            href={"mailto:bryan@bbvandermark.com"}
                            style={{color: isDarkMode ? "white" : "black"}}
                        >bryan@bbvandermark.com</a>.
                    </h3>
                </div>
                {!mobile && <PhotoCollage images={images}/>}
            </div>
        </>
    );
}

export default About;