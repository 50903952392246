import React, {useEffect} from "react";
import Banner from "../components/Banner";
import HomeCollage from "../components/HomeCollage";

function Home() {

    useEffect(() => {
        document.title = "Bryan's Blog & Projects";
    }, []);

    return (
        <>
            <Banner/>
            <HomeCollage />
        </>
    )
}

export default Home;