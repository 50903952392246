import {useEffect, useState} from "react";

import styles from "./Sorted.module.css";

interface SortedProps {
    title: string;
    tagline: string;
    darkMode: boolean;
}

interface Letter {
    letter: string;
    index: number;
}

const delay = (ms: number) => new Promise(
    resolve => setTimeout(resolve, ms)
);

function randomizeToLetterArr(string: string) {
    return string.split("").map((letter, index) => {
        let letterObj: Letter = {
            letter: letter,
            index: index
        }
        return letterObj;
    }).sort((_) => {
        return Math.random() - 0.5;
    });
}

function insertionSortStep(arr: Letter[], index: number) {
    let copy = [...arr]; // The arrays are guaranteed to be small, so this is fine
    for(let i = index; i < arr.length; i++) {
        if(copy[i].index === index) { // Could keep track of a current minimum, but we know we are looking for index
            let tmp = copy[i];
            copy[i] = copy[index];
            copy[index] = tmp;
        }
    }
    return copy;
}

const Sorted = (props: SortedProps) => {

    const [initialized, setInitialized] = useState<boolean>(false);
    const [titleArray, setTitleArray] = useState<Letter[]>([]);
    const [index, setIndex] = useState<number>(0);

    const hoverText = "This is a visualization of insertion sort!"

    useEffect(() => {
        async function sort() {
            if (initialized && index === 0) {
                await delay(500);
            }

            // Create random letter array
            if (!initialized) {
                let titleArray_init = randomizeToLetterArr(props.title);

                setTitleArray(titleArray_init);
                setInitialized(true);
            } else if (index < titleArray.length){
                let new_title = insertionSortStep(titleArray, index);
                await delay(100);

                setTitleArray(new_title);
                setIndex(index + 1);
            }
        }
        sort().catch((_) => { console.log("This shouldn't ever be called - famous last words") });
    });

    let sortedClass = props.darkMode ? styles.sorted_letter_dark : styles.sorted_letter_light;
    let doneClass = props.darkMode ? styles.done_letter_dark : styles.done_letter_light;


    return (
        <div className={styles.sorted}>
            <h1 title={hoverText}>{titleArray.map((letter, idx) => {
                // Done sorting
                if (index === titleArray.length) {
                    return <span className={doneClass} key={idx}>{letter.letter}</span>
                }
                // Still sorting
                if (idx < index) {
                    return <span className={sortedClass} key={idx}>{letter.letter}</span>
                } else if (idx === index && idx !== 0) {
                    return <span className={styles.current_letter} key={idx}>{letter.letter}</span>
                } else {
                    return <span className={styles.unsorted_letter} key={idx}>{letter.letter}</span>
                }
            })}</h1>
            <h2 className={doneClass}>{props.tagline}</h2>
        </div>
    )
}

export default Sorted;