import {Divider} from "@mui/material-next";
import React, {useEffect} from "react";
import ArticleLister from "../components/ArticleLister";

function Articles() {

    useEffect(() => {
        document.title = "Articles";
    }, []);

    return (
        <>
            <Divider variant="middle" />
            <ArticleLister />
        </>
    );
}

export default Articles;