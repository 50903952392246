import React, {FC, useState} from "react";

interface DarkModeContextProps {
    isDarkMode: boolean;
    setIsDarkMode: (val: boolean) => void;
}

const DarkModeContext = React.createContext<DarkModeContextProps>({
    isDarkMode: true,
    setIsDarkMode: (_) => {}
});

interface DarkModeProviderProps {
    children: React.ReactNode;
}

export const DarkModeProvider: FC<DarkModeProviderProps> = ({children}) => {
    const [isDarkMode, setIsDarkMode] = useState(true);

    return (
        <DarkModeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
            {children}
        </DarkModeContext.Provider>
    );
}

export const useDarkMode = () => {
    const context = React.useContext(DarkModeContext);
    if (!context) {
        throw new Error('useDarkMode must be used within a DarkModeProvider');
    }
    return context;
};