import React, {useEffect} from "react";
import {useMediaQuery} from "react-responsive";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import styles from "./DarkButton.module.css";
import { useDarkMode } from "../contexts/DarkModeContext";

export const DarkButton = () => {
    const { isDarkMode, setIsDarkMode } = useDarkMode();

    useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined,
        (isSystemDark) => setIsDarkMode(isSystemDark)
    );

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDarkMode]);

    return (
        <button className={styles.button}
                onClick={() => setIsDarkMode(!isDarkMode)} >
            {isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
        </button>
    );
}

export default DarkButton;