import React, {useEffect, useState} from "react";
import ArticlePreview from "./ArticlePreview";
import styles from "./ArticleLister.module.css";
import { useSearchParams } from "react-router-dom";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";

interface PaginationParams {
    page: number;
    take: number;
}

const createUrl = (paginationParams: PaginationParams, count: boolean, tag?: string, search?: string): string => {
    let suffix;
    if(count){
        suffix = "/article_count"
    }
    else{
        suffix = "/articles"
    }
    const url = new URL(process.env.REACT_APP_API_URL + "/api" + suffix)
    if (tag){
        url.searchParams.append("tag", tag);
    }
    if (search){
        url.searchParams.append("search", search);
    }
    url.searchParams.append("page", paginationParams.page.toString());
    url.searchParams.append("take", paginationParams.take.toString());
    return url.toString();
}

export const getArticles = async (paginationParams: PaginationParams, tag?: string, search?: string): Promise<string[]> => {
    try {
        const url = createUrl(paginationParams, false, tag, search);
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch articles');
        }
        return (await response.json()).articles;
    }
    catch (error) {
        console.error(error);
        return [];
    }
}

export const getArticleCount = async (paginationParams: PaginationParams, tag?: string, search?: string): Promise<number> => {
    try {
        const url = createUrl(paginationParams, true, tag, search);
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch article count');
        }
        return (await response.json()).count;
    }
    catch (error) {
        console.error(error);
        return 0;
    }
}

export const getTags = async (): Promise<string[]> => {
    try {
        const url = process.env.REACT_APP_API_URL + "/api/tags";
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch tags');
        }
        return (await response.json()).tags;
    }
    catch (error) {
        console.error(error);
        return [];
    }
}

function ArticleLister() {
    const [articles, setArticles] = useState<string[]>([]);
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [tag, setTag] = useState<string | undefined>(undefined);
    const [paginationParams, setPaginationParams] = useState<PaginationParams>({page: 0, take: 5});
    const [totalArticles, setTotalArticles] = useState(0);
    const [search, setSearch] = useState("");
    const [tags, setTags] = useState<string[]>([]);
    const [tagsOpen, setTagsOpen] = useState(false);

    const setPaginationPage = (page: number) => {
        const newParams: PaginationParams = {
            ...paginationParams,
            page
        }
        setPaginationParams(newParams);
    }

    const setPaginationTake = (take: number) => {
        const newParams: PaginationParams = {
            ...paginationParams,
            take
        }
        setPaginationParams(newParams);
    }

    const isPreviousEnabled = () => {
        return paginationParams.page > 0;
    }

    const isNextEnabled = () => {
        const totalPages = Math.ceil(totalArticles / paginationParams.take);
        return paginationParams.page + 1 < totalPages
    }

    const previousPage = () => {
        setPaginationPage(paginationParams.page - 1);
    }

    const nextPage = () => {
        setPaginationPage(paginationParams.page + 1);
    }

    useEffect(() => {
        setIsLoading(true);
        const count = getArticleCount(paginationParams, tag, search).then((total) => {
            setTotalArticles(total);
        });
        const articles = getArticles(paginationParams, tag, search).then((articles) => {
            if(articles.length > 0 && articles[0] !== null) {
                setArticles(articles);
            }
            else {
                setArticles([]);
            }
        });
        Promise.allSettled([count, articles]).then(() => {setIsLoading(false);})
    }, [tag, search, paginationParams]);

    useEffect(() => {
        const tag = searchParams.get("tag");
        if(tag === null){
            setTag(undefined);
            return;
        }
        setTag(tag);
        setPaginationPage(0);
        // seems to work fine without setPaginationPage as a dependency, but freezes the browser when adding it.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    useEffect(() => {
        setPaginationPage(0); // reset to page 0 when changing the take
        // seems to work fine without setPaginationPage as a dependency, but freezes the browser when adding it.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationParams.take])

    useEffect(() => {
        getTags().then((tags) => {
            setTags(tags);
        });
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.search}>
                <h4>
                    Search for articles:
                </h4>
                <TextField
                    variant='standard'
                    type="text"
                    value={search}
                    placeholder="Enter search term"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Autocomplete
                    options={tags}
                    autoHighlight
                    onChange={(_, value) => {value ? setTag(value) : setTag(undefined)}}
                    open={tagsOpen}
                    onOpen={() => setTagsOpen(true)}
                    onClose={() => setTagsOpen(false)}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder="Enter tag"
                            variant='standard'
                        />
                    }
                    value={tag ? tag : null}
                />
            </div>
            <div className={styles.articles}>
                <div className={styles.rssContainer}>
                    <a href={process.env.REACT_APP_API_URL + "/rss.xml"}>RSS</a>
                </div>
                {isLoading && <div className={styles.loading}>
                    <CircularProgress sx={{color: 'var(--primary)'}} />
                </div>}
                {!isLoading && articles.length === 0 && <p className={styles.notFound}>No articles found.</p>}
                {!isLoading && articles.map((article, index) => (
                    <ArticlePreview name={article} key={index}/>
                ))}
            </div>
            <div className={styles.pageInfo}>
                <h4>
                    Articles per page:
                </h4>
                <div className={styles.pagination}>
                    <select>
                        <option value="5" onClick={() => setPaginationTake(5)}>5</option>
                        <option value="10" onClick={() => setPaginationTake(10)}>10</option>
                        <option value="15" onClick={() => setPaginationTake(15)}>15</option>
                        <option value="-1" onClick={() => setPaginationTake(-1)}>All</option>
                    </select>
                    <div className={styles.buttons}>
                        <button disabled={!isPreviousEnabled()} onClick={previousPage}>Previous</button>
                        <button disabled={!isNextEnabled()} onClick={nextPage}>Next</button>
                    </div>
                </div>
                <h4>
                    Page {paginationParams.page + 1} of {isLoading ? '...' : paginationParams.take === -1 ? 1 : Math.ceil(totalArticles / paginationParams.take)}
                </h4>
            </div>
        </div>
    );
}

export default ArticleLister;