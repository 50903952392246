import {Divider} from "@mui/material-next";
import React, {useEffect} from "react";
import ProjectLister from "../components/ProjectLister";

function Projects() {

    useEffect(() => {
        document.title = "Projects";
    }, []);

    return (
        <>
            <Divider variant="middle" />
            <ProjectLister />
        </>
    );
}

export default Projects;