import styles from './PhotoCollage.module.css';

export interface PhotoCollageProps {
    images: string[];
}

const PhotoCollage = ({images}: PhotoCollageProps) => {
    return (
        <div className={styles.container}>
            {images.map((image, index) => (
                <div
                    key={index}
                    className={styles.photo}
                >
                    <img src={image} style={{ width: '100%', height: '100%' }}  alt={"Image"}/>
                </div>
            ))}
        </div>
    );
}

export default PhotoCollage