export const fragmentShader = `

uniform vec3 iResolution;
uniform float iTime;
uniform vec2 random;
uniform bool darkMode;

//Perlin noise functions
//https://en.wikipedia.org/wiki/Perlin_noise
float interpolate(float a0, float a1, float w) {
    return (a1 - a0) * ((w * (w * 6.0 - 15.0) + 10.0) * w * w * w) + a0;
}

vec2 randomGradient(int ix, int iy) {
    // No precomputed gradients mean this works for any number of grid coordinates
    int w = 312;
    int s = w / 2; // rotation width
    int a = ix, b = iy;
    a *= 3284157443; b ^= a << s | a >> w-s;
    b *= 1911520717; a ^= b << s | b >> w-s;
    a *= 2048419325;
    float random = float(a) * (3.14159265 / float(~(~0u >> 1))); // in [0, 2*Pi]
    vec2 v;
    v.x = cos(random); v.y = sin(random);
    return v;
}

// Computes the dot product of the distance and gradient vectors.
float dotGridGradient(int ix, int iy, float x, float y) {
    // Get gradient from integer coordinates
    vec2 gradient = randomGradient(ix, iy);

    // Compute the distance vector
    float dx = x - float(ix);
    float dy = y - float(iy);

    // Compute the dot-product
    return (dx*gradient.x + dy*gradient.y);
}

// Compute Perlin noise at coordinates x, y
float perlin(float x, float y) {
    // Determine grid cell coordinates
    int x0 = int(floor(x));
    int x1 = x0 + 1;
    int y0 = int(floor(y));
    int y1 = y0 + 1;

    // Determine interpolation weights
    // Could also use higher order polynomial/s-curve here
    float sx = x - float(x0);
    float sy = y - float(y0);

    // Interpolate between grid point gradients
    float n0, n1, ix0, ix1, value;

    n0 = dotGridGradient(x0, y0, x, y);
    n1 = dotGridGradient(x1, y0, x, y);
    ix0 = interpolate(n0, n1, sx);

    n0 = dotGridGradient(x0, y1, x, y);
    n1 = dotGridGradient(x1, y1, x, y);
    ix1 = interpolate(n0, n1, sx);

    value = interpolate(ix0, ix1, sy);
    return value; // Will return in range -1 to 1. To make it in range 0 to 1, multiply by 0.5 and add 0.5
}

void mainImage( out vec4 fragColor, in vec2 fragCoord )
{
    // Normalized pixel coordinates (from 0 to 1)
    vec2 uv = fragCoord/iResolution.xy;
    float offset = iTime / 10.0 * (2000.0 / iResolution.x);

    // Time varying pixel color
    vec3 col = 0.5 + 0.5*cos(iTime+uv.xyx+vec3(0,2,4));
    
    float p1 = perlin(uv.x + offset, uv.y + offset);
    float p2 = perlin(uv.x + offset + random.x * 1000.0, uv.y + offset + random.x * 1000.0);
    float p3 = perlin(uv.x + offset + random.y * 1000.0, uv.y + offset + random.y * 1000.0);
    vec3 noise = vec3(p1, p2, p3);
    if (!darkMode) {
        noise = 1.0 - noise;
    }
    // Output to screen
    fragColor = vec4(noise, 1.0);
}

void main() {
  mainImage(gl_FragColor, gl_FragCoord.xy);
}
`

export const vertexShader = `
                varying vec2 vUv;
              void main() {
                vUv = uv;
                gl_Position = vec4( position, 1.0 );
              }
        `;