import {MarkdownMetadata, getArticleMetadata} from "./MarkdownViewer";
import {useEffect, useState} from "react";
import styles from "./ArticlePreview.module.css";
import {useNavigate} from "react-router-dom";

export interface ArticlePreviewProps {
    name: string;
}

const ArticlePreview = (props: ArticlePreviewProps) => {
    const [metadata, setMetadata] = useState<MarkdownMetadata>({title: "", author: "", date: "", abstract: "", tags: []});

    useEffect(() => {
        getArticleMetadata(props.name).then((metadata) => {
            setMetadata(metadata);
        });
    }, [props.name]);

    const navigate = useNavigate();
    const showArticle = () => {
        navigate("/articles/" + props.name);
    }

    const showTag = (tag: string) => {
        navigate("/articles?tag=" + tag);
    }

    return (
        <div className={styles.container} onClick={showArticle}>
            <div className={styles.title}>
                {metadata.title}
            </div>
            <div className={styles.context}>
                <span>{metadata.author}</span>
                <span>{metadata.date}</span>
            </div>
            <div className={styles.abstract}>
                {metadata.abstract}
            </div>
            <div className={styles.tags}>
                {metadata.tags!.map((tag, index) => (
                    <span key={index}
                        className={styles.tag}
                          onClick={(e) => {
                              e.stopPropagation();
                              showTag(tag);
                          }
                    }>{tag}</span>
                ))}
            </div>
        </div>
    )
}

export default ArticlePreview;