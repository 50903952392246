import Sorted from "./doodles/Sorted";
import ShaderBackground from "./background/ShaderBackground";
import styles from "./Banner.module.css";
import {useRef} from "react";
import {useDarkMode} from "../contexts/DarkModeContext";

const title: string = "Bryan's Blog & Projects";
const tagline: string = "This is a place where I post projects and things that I find interesting";

function Banner() {
    const backgroundContainerRef = useRef<HTMLDivElement>(null);
    const { isDarkMode } = useDarkMode();
  return (
    <div className={styles.banner}>
        <div className={styles.banner_background} ref={backgroundContainerRef}>
            <ShaderBackground darkMode={isDarkMode} container={backgroundContainerRef}/>
        </div>
        <div className={styles.banner_text}>
            <Sorted title={title} tagline={tagline} darkMode={isDarkMode}/>
        </div>
    </div>
  )
}

export default Banner;