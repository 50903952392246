import styles from "./HomeCollage.module.css";
import React, {useEffect} from "react";
import ArticlePreview from "./ArticlePreview";
import EmailIcon from '@mui/icons-material/Email';
import ProjectPreview from "./ProjectPreview";

function HomeCollage() {
    const [featuredArticles, setFeaturedArticles] = React.useState<string[]>([]);
    const [featuredProjects, setFeaturedProjects] = React.useState<string[]>([]);

    useEffect(() => {
        try {
            fetch(process.env.REACT_APP_API_URL + "/api/articles/featured")
                .then(response => response.json())
                .then(data => {
                    setFeaturedArticles(data.articles);
                });
        }
        catch (error) {
            console.error(error);
        }

        try {
            fetch(process.env.REACT_APP_API_URL + "/api/projects/featured")
                .then(response => response.json())
                .then(data => {
                    setFeaturedProjects(data.projects);
                });
        }
        catch (error) {
            console.error(error);
        }
    }, []);


    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.leftTop}>
                    <h2>Featured articles:</h2>
                    <div className={styles.articleContainer}>
                        {featuredArticles && featuredArticles.map((featuredArticle, index) => (
                            <ArticlePreview name={featuredArticle} key={index}/>
                        ))}
                    </div>
                    <h3><a href={"/articles"}>{">"} All articles</a></h3>
                </div>
                <div className={styles.rightTop}>
                    <h2>Featured project:</h2>
                    <div className={styles.projectContainer}>
                        {featuredProjects && featuredProjects.map((featuredProject, index) => (
                            <ProjectPreview name={featuredProject} key={index}/>
                        ))}
                    </div>
                    <h3><a href={"/projects"}>{">"} All projects</a></h3>
                </div>
            </div>
            <div className={styles.bottom}>
                <h3>Get in contact:</h3>
                <a href={"mailto:bryan@bruhyan.com"}>
                    <EmailIcon fontSize={"large"}/>
                     bryan@bruhyan.com
                </a>
            </div>
        </div>
    )
}

export default HomeCollage;