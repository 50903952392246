import React, {useEffect, useState} from "react";
import styles from "./ProjectLister.module.css";
import ProjectPreview from "./ProjectPreview";

export const getProjects = async (): Promise<string[]> => {
    try {
        const url = new URL(process.env.REACT_APP_API_URL + "/api/projects").toString();
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch projects');
        }
        return (await response.json()).projects;
    }
    catch (error) {
        console.error(error);
        return [];
    }
}

function ProjectLister() {
    const [projects, setProjects] = useState<string[]>([]);

    useEffect(() => {
        getProjects().then((data) => {
            if(data.length > 0 && data[0] !== null) {
                setProjects(data);
            }
            else {
                setProjects([]);
            }
        })
    }, []);

    return (
        <div className={styles.container}>
            <ul>
                {projects.length === 0 && <p>Nothing here yet...</p>}
                {projects.map((project, index) => (
                    <ProjectPreview name={project} key={index}/>
                ))}
            </ul>
        </div>
    );
}

export default ProjectLister;