import Markdown from "react-markdown";
import React, {useEffect, useState} from "react";
import remarkGfm from 'remark-gfm';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {coldarkCold as lightTheme, coldarkDark as darkTheme} from 'react-syntax-highlighter/dist/esm/styles/prism';
import styles from "./MarkdownViewer.module.css";
import {useDarkMode} from "../contexts/DarkModeContext";
import {useLocation, useParams} from "react-router-dom";
import {Divider} from "@mui/material-next";
import { Helmet } from "react-helmet";

interface HeaderData {
    text: string;
    level: number;
}

export interface MarkdownMetadata {
    title: string;
    author: string;
    date: string;
    abstract: string;
    tags?: string[];
    image?: string;
}

export const getArticleMetadata = async (name: string): Promise<MarkdownMetadata> => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/articles/" + name + "?meta=true");
        if (!response.ok) {
            throw new Error('Failed to fetch article metadata');
        }
        return (await response.json()).metadata;
    }
    catch (error) {
        console.error(error);
        return {title: "Failed to load", author: "-", date: "-", abstract: "-", tags: []};
    }
}

export const getArticle = async (name: string): Promise<string> => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/articles/" + name);
        if (!response.ok) {
            throw new Error('Failed to fetch article');
        }
        return await (await response.json()).content;
    }
    catch (error) {
        console.error(error);
        return "Failed to load";
    }
}

export const getProjectMetadata = async (name: string): Promise<MarkdownMetadata> => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/projects/" + name + "?meta=true");
        if (!response.ok) {
            throw new Error('Failed to fetch article metadata');
        }
        return (await response.json()).metadata;
    }
    catch (error) {
        console.error(error);
        return {title: "Failed to load", author: "-", date: "-", abstract: "-", image: ""};
    }
}

export const getProject = async (name: string): Promise<string> => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/projects/" + name);
        if (!response.ok) {
            throw new Error('Failed to fetch article');
        }
        return await (await response.json()).content;
    }
    catch (error) {
        console.error(error);
        return "Failed to load";
    }
}

const MarkdownViewer = () => {
    const [markdown, setMarkdown] = useState("");
    const [style, setStyle] = useState(darkTheme);
    const { isDarkMode } = useDarkMode();
    const [metadata, setMetadata] = useState<MarkdownMetadata>({title: "", author: "", date: "", abstract: "", tags: []});
    const [headers, setHeaders] = useState<HeaderData[]>([]);
    let { name } = useParams();
    const location = useLocation();
    const isProject = location.pathname.includes('/projects/');

    useEffect(() => {
        if(name === undefined){
            return;
        }
        const getter = isProject ? getProject : getArticle;
        const metaGetter = isProject ? getProjectMetadata : getArticleMetadata;

        getter(name).then((markdown) => {
            setMarkdown(markdown);
        });
        metaGetter(name).then((metadata) => {
            setMetadata(metadata);
        });
    }, [name, isProject]);


    useEffect(() => {
        if(isDarkMode){
            setStyle(darkTheme);
        }
        else{
            setStyle(lightTheme);
        }
    }, [isDarkMode]);

    useEffect(() => {
        const headerRegex = /^#+\s+(.*)/gm;
        let parsed_headers = markdown.match(headerRegex);
        if(parsed_headers === null){
            return;
        }
        let new_headers = [];
        for(let i = 0; i < parsed_headers.length; i++) {
            const index = parsed_headers[i].indexOf(' ');
            if (index === -1) {
                continue;
            }
            const tags = parsed_headers[i].substring(0, index);
            const text_raw = parsed_headers[i].substring(index + 1);

            let level = tags.trim().length;
            let text = text_raw.trim();
            if(level !== 1) {
                new_headers.push({text, level});
            }
        }
        setHeaders(new_headers);
    }, [markdown])

    const getHeaderClass = (level: number) => {
        switch (level) {
            case 2:
                return styles.section;
            case 3:
                return styles.subsection
            case 4:
                return styles.subsubsection;
            case 5:
                return styles.subsubsubsection;
            case 6:
                return styles.subsubsubsubsection;
            default:
                return styles.section;
        }
    }

    const getHeaderId = (text: string) => {
        return text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    return (
        <>
        <Helmet>
            <title>{metadata.title}</title>
            <meta name="description" content={metadata.abstract}/>
            {metadata.tags && <meta name="keywords" content={metadata.tags?.join(", ")}/>}
            <meta name="author" content={metadata.author}/>
            <meta name="date" content={metadata.date}/>
            <meta property="og:title" content={metadata.title}/>
            <meta property="og:description" content={metadata.abstract}/>
            <meta property="og:type" content="article"/>
            <meta property="og:url" content={window.location.href}/>
            {metadata.image && <meta property="og:image" content={metadata.image}/>}
            <meta property="article:author" content={metadata.author}/>
            <meta property="article:published_time" content={metadata.date}/>
            <meta charSet="UTF-8"/>
        </Helmet>
        <Divider variant="middle" />
        <div className={styles.article}>
            <div className={styles.metadata}>
                <h1>{metadata.title}</h1>
                <div className={styles.details}>
                    <span>{metadata.author}</span>
                    <span>{metadata.date}</span>
                </div>
                <span className={styles.abstract}>{metadata.abstract}</span>
            </div>
            {headers.length > 0 && (
                <div className={styles.headers}>
                    <h2>Contents</h2>
                    <ul>
                        {headers.map((header, index) => (
                            <li key={index} className={getHeaderClass(header.level)}>
                                <a href={`#${getHeaderId(header.text)}`}>{header.text}</a>
                            </li>
                        ))}
                    </ul>
                </div>

            )}
            <Markdown remarkPlugins={[remarkGfm]} components={
                {
                    code(props) {
                        const {children, className, node, ...rest} = props
                        const match = /language-(\w+)/.exec(className || '')
                        return match ? (
                            <SyntaxHighlighter
                                PreTag="div"
                                children={String(children).replace(/\n$/, '')}
                                language={match[1]}
                                style={style}
                            />
                        ) : (
                            <code {...rest} className={className}>
                                {children}
                            </code>
                        )
                    },
                    h1(_) {
                        return <></> // Don't render the title
                    },
                    h2(props) {
                        return <h2 id={getHeaderId(props.children?.toString()!)}>{props.children}</h2>
                    },
                    h3(props) {
                        return <h3 id={getHeaderId(props.children?.toString()!)}>{props.children}</h3>
                    },
                    h4(props) {
                        return <h4 id={getHeaderId(props.children?.toString()!)}>{props.children}</h4>
                    },
                    h5(props) {
                        return <h5 id={getHeaderId(props.children?.toString()!)}>{props.children}</h5>
                    },
                    h6(props) {
                        return <h6 id={getHeaderId(props.children?.toString()!)}>{props.children}</h6>
                    },
                }
            }>{markdown}</Markdown>
        </div>
        </>
    );
}

export default MarkdownViewer;