import React from 'react';
import './App.css';
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Articles from "./pages/Articles";
import Projects from "./pages/Projects";
import About from "./pages/About";
import MarkdownViewer from "./components/MarkdownViewer";

function App() {
  return (
    <Router>
        <Navbar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/articles/:name" element={<MarkdownViewer />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:name" element={<MarkdownViewer />} />
            <Route path="/about" element={<About />} />
        </Routes>
    </Router>
  );
}

export default App;
