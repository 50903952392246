import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.css";
import DarkButton from "./DarkButton";

function Navbar() {
    return (
        <nav className={styles.background}>
            <ul className={styles.list}>
                <li className={styles.list_item}>
                    <Link to="/">Home</Link>
                </li>
                <li className={styles.list_item}>
                    <Link to="/articles">Articles</Link>
                </li>
                <li className={styles.list_item}>
                    <Link to="/projects">Projects</Link>
                </li>
                <li className={styles.list_item}>
                    <Link to="/about">About me</Link>
                </li>
            </ul>
            <DarkButton/>
        </nav>
  );
}

export default Navbar;