import {MarkdownMetadata, getProjectMetadata} from "./MarkdownViewer";
import {useEffect, useState} from "react";
import styles from "./ProjectPreview.module.css";
import {useNavigate} from "react-router-dom";

export interface ProjectPreviewProps {
    name: string;
}

const ProjectPreview = (props: ProjectPreviewProps) => {
    // In this case, "author" will be the person/company/institution that the project was done for.
    const [metadata, setMetadata] = useState<MarkdownMetadata>({title: "", author: "", date: "", abstract: "", tags: [], image: ""});

    useEffect(() => {
        getProjectMetadata(props.name).then((metadata) => {
            setMetadata(metadata);
        });
    }, [props.name]);

    const navigate = useNavigate();
    const showProject = () => {
        navigate("/projects/" + props.name);
    }

    return (
        <div className={styles.container} onClick={showProject}>
            <div className={styles.title}>
                {metadata.title}
            </div>
            <div className={styles.context}>
                <span>{metadata.author}</span>
                <span>{metadata.date}</span>
            </div>
            <div className={styles.abstract}>
                {metadata.abstract}
            </div>
            {metadata.image &&
                (<div className={styles.image}>
                    <img src={process.env.REACT_APP_API_URL + metadata.image} alt=""/>
                </div>)}
        </div>
    )
}

export default ProjectPreview;